import { obtainHomeMusicScoreData , obtainMainBanner } from '../../../config/api'
import {MusicScore , BannerInfo} from '../../../proto/proto';
/**
 * 
 * @param {*} type  action 类型 
 * @param {*} data  action 数据  对象形式
 */
export const commonAction = (type, data) => ({
	type,
	data
});

// 获取首页吉他谱数据
export const acquireHomeMusicScore = ( type ) => {
	return async (dispatch) => {
		let data = await obtainHomeMusicScoreData(MusicScore,'MusicScore.MusicScoreReqMsg','MusicScore.HomeMusicScoreResMsg');
		dispatch(commonAction(type,data.data));
	}
};
// 获取首页banner数据
export const acquireMainBanner = ( type ) => {
	return async (dispatch) => {
		let data = await obtainMainBanner(BannerInfo, 'BannerInfo.MainBannerReqMsg', 'BannerInfo.MainBannerResMsg');
		data = JSON.parse(data.data)
		dispatch(commonAction(type,data));
	}
};