import * as actionTypes from './actionTypes';
const defaultState = {
	// 曲谱
	musicScore: [],
	// 曲谱 总数
	scoreTotal: 0,
};

export default (state = defaultState, action) => {
	console.log(action)
	let newState = JSON.parse(JSON.stringify(state));
	switch(action.type) {
		case actionTypes.Obtain_Ukulele_Music_Score:
			newState.scoreTotal = action.data.total;
			newState.musicScore = action.data.data;
			return newState;
		default:
			return state;
	}
}