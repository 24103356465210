/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/light");

var $root = ($protobuf.roots["default"] || ($protobuf.roots["default"] = new $protobuf.Root()))
.addJSON({
  BannerInfo: {
    nested: {
      MainBannerReqMsg: {
        fields: {}
      },
      MainBannerResMsg: {
        fields: {
          msg: {
            type: "string",
            id: 1
          },
          status: {
            type: "int32",
            id: 2
          },
          data: {
            type: "string",
            id: 3
          }
        }
      }
    }
  },
  MusicScore: {
    nested: {
      MusicScoreDetailsReqMsg: {
        fields: {
          id: {
            type: "string",
            id: 1
          }
        }
      },
      MusicScoreDetailsResMsg: {
        fields: {
          msg: {
            type: "string",
            id: 1
          },
          status: {
            type: "int32",
            id: 2
          },
          data: {
            type: "MusicScoreList",
            id: 3
          }
        }
      },
      MusicScoreReqMsg: {
        fields: {
          type: {
            type: "int32",
            id: 1
          },
          state: {
            type: "int32",
            id: 2
          },
          page: {
            type: "int32",
            id: 3
          },
          size: {
            type: "int32",
            id: 4
          }
        }
      },
      SearchScoreReqMsg: {
        fields: {
          type: {
            type: "int32",
            id: 1
          },
          state: {
            type: "int32",
            id: 2
          },
          page: {
            type: "int32",
            id: 3
          },
          size: {
            type: "int32",
            id: 4
          },
          text: {
            type: "string",
            id: 5
          }
        }
      },
      MusicScoreResMsg: {
        fields: {
          msg: {
            type: "string",
            id: 1
          },
          status: {
            type: "int32",
            id: 2
          },
          data: {
            rule: "repeated",
            type: "MusicScoreList",
            id: 3
          },
          total: {
            type: "int32",
            id: 4
          }
        }
      },
      MusicScoreList: {
        fields: {
          id: {
            type: "int32",
            id: 1
          },
          hot: {
            type: "int32",
            id: 2
          },
          frontMonthHot: {
            type: "int32",
            id: 12
          },
          img: {
            type: "string",
            id: 3
          },
          name: {
            type: "string",
            id: 4
          },
          singer: {
            type: "string",
            id: 5
          },
          singerImg: {
            type: "string",
            id: 6
          },
          status: {
            type: "int32",
            id: 7
          },
          tags: {
            type: "string",
            id: 8
          },
          type: {
            type: "string",
            id: 9
          },
          createTime: {
            type: "int64",
            id: 10
          },
          updateTime: {
            type: "int64",
            id: 11
          }
        }
      },
      HomeMusicScoreResMsg: {
        fields: {
          msg: {
            type: "string",
            id: 1
          },
          status: {
            type: "int32",
            id: 2
          },
          data: {
            type: "HomeMusicScoreList",
            id: 3
          }
        }
      },
      HomeMusicScoreList: {
        fields: {
          hotGuitar: {
            rule: "repeated",
            type: "MusicScoreList",
            id: 1
          },
          newGuitar: {
            rule: "repeated",
            type: "MusicScoreList",
            id: 2
          },
          hotUKulele: {
            rule: "repeated",
            type: "MusicScoreList",
            id: 3
          },
          newUKulele: {
            rule: "repeated",
            type: "MusicScoreList",
            id: 4
          }
        }
      }
    }
  }
});

module.exports = $root;
