import React, { Component, Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import HeadNavbar from './components/headNavbar';
import * as asyncComponent from './load';
import store from './store';



class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Fragment>
            <HeadNavbar></HeadNavbar>
            <Fragment>
              <Route path='/' exact component={asyncComponent.AsyncHomePage}></Route>
              <Route path='/appEclipse' exact component={asyncComponent.AsyncAppEclipse}></Route>
              <Route path='/guitarScore' exact component={asyncComponent.AsyncGuitarScore}></Route>
              <Route path='/uKuleleScore' exact component={asyncComponent.AsyncUKuleleScore}></Route>
              <Route path='/scoreParticulars' exact component={asyncComponent.AsyncScoreParticulars}></Route>
              <Route path='/scoreSearch' exact component={asyncComponent.AsyncScoreSearch}></Route>
            </Fragment>
          </Fragment>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
