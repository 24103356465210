/**
 * 配置环境
 *
 * BASEURL: 域名地址
 * ALICLOUD: 阿里云环境
 */
// 阿里云oss
import OSS from 'ali-oss';
// 域名地址
// 本地调试
// let BASEURL = 'http://172.13.31.241:7777/api'
// 测试服
// let BASEURL = 'http://www.yueqishe.cn/api'
// 正式服
let BASEURL = 'http://www.yueqi-she.com/api'

// 阿里云oss环境
let ALICLOUD = new OSS({
  region: 'oss-cn-hangzhou',
  accessKeyId: 'LTAIzsvPrCVvXfRA',
  accessKeySecret: 'O6RdKfB0fZhbqLeLhWSvjs0YSloir9',
  bucket: 'mobuz-live'
});

export {
  BASEURL,
  ALICLOUD
}
