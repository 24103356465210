import Loadable from 'react-loadable';
import component from './component';
export const AsyncAppEclipse = Loadable({
    loader: () => import('../pages/appEclipse'),
    loading: component
});

export const AsyncHomePage = Loadable({
    loader: () => import('../pages/homePage'),
    loading: component
});
export const AsyncGuitarScore = Loadable({
    loader: () => import('../pages/guitarScore'),
    loading: component
});
export const AsyncScoreParticulars = Loadable({
    loader: () => import('../pages/scoreParticulars'),
    loading: component
});
export const AsyncScoreSearch = Loadable({
    loader: () => import('../pages/scoreSearch'),
    loading: component
});
export const AsyncUKuleleScore = Loadable({
    loader: () => import('../pages/uKuleleScore'),
    loading: component
});

