import React from 'react';
import ReactDOM from 'react-dom';
// 适配 rem
import './config/rem';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

(function (w, d, n, a, j) {
    w[n] = w[n] || function () {
        (w[n].a = w[n].a || []).push(arguments)
    };
    j = d.createElement('script');
    j.async = true;
    j.src = 'https://qiyukf.com/script/6c559d831c7ea1795aacf6acbb3d94d5.js';
    d.body.appendChild(j);
  })(window, document, 'ysf');
  
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
