import { combineReducers } from 'redux';
import { reducer as headNavbarReducer } from '../components/headNavbar/store';
import { reducer as homePageReducer } from '../pages/homePage/store';
import { reducer as guitarScoreReducer } from '../pages/guitarScore/store';
import { reducer as uKuleleScoreReducer } from '../pages/uKuleleScore/store';
import { reducer as scoreParticularsReducer } from '../pages/scoreParticulars/store';
import { reducer as scoreSearchReducer } from '../pages/scoreSearch/store';

const reducer = combineReducers({
    headNavbar: headNavbarReducer,
    homePage: homePageReducer,
    guitarScore: guitarScoreReducer,
    uKuleleScore: uKuleleScoreReducer,
    scoreParticulars: scoreParticularsReducer,
    scoreSearch: scoreSearchReducer,
    
});

export default reducer;