import { BASEURL } from './env'
import axios from 'axios' //引入axios


/** 
 * matter： protobuf 协议proto
 * means：  protobuf 方法proto
 * data： 数据对象
 * buffer： 二进制对象
*/
// 编译protobuf 数据
export const compileRequestMessage = async(matter, means, data) => {
    // 获取请求协议
    let protoRequest = await matter.lookupType(means);
    // 直接生成对象
    let protoMessage = await protoRequest.create(data);
    // 加密
    let protoBuffer = await protoRequest.encode(protoMessage).finish();
    // 去掉多余的编码（ 00 00 00 ... ） 重点去掉
    protoBuffer = protoBuffer.slice(0, protoBuffer.length);
    return protoBuffer;
}
// 解析protobuf 数据
export const analysisResponseMessage = async(matter, means, buffer) => {
    // 获取响应协议
    let protoResponse = await matter.lookupType(means);
    let protoUint = new Uint8Array(buffer, buffer.length);
    // 解码
    let jsonData = await protoResponse.decode(protoUint); 
    return jsonData;
}
/** 
 * agreement: proto 协议
 * demand: proto 请求modal
 * respond: proto 响应modal
 * url: 链接  
 * method: 方法    
 * data: 参数    
 * BASEURL: 大数据的后台url 
 * isProto: 是否使用protobuf 
* */
export const fetch = (agreement, demand, respond, method, url, data, isProto = false) => {
    // console.log(method, url, data);
    return new Promise(async (resolve, reject) => {
        let header = {}
        // 使用 protobuf
        if ( isProto ) {
            const protoBuffer = await compileRequestMessage(agreement, demand, data);
            console.log(agreement)
            header = {
                "Content-Type": "application/octet-stream;charset=utf-8"
            }
            axios.request({
                method,
                data: protoBuffer, 
                headers: header,
                url: BASEURL + url,
                responseType: 'arraybuffer' // protobuf发送类型
            }).then(async response => {
                // resolve(response.data.data)
                console.log(response.data)
                const jsonData = await analysisResponseMessage(agreement, respond, response.data)
                // console.log(jsonData, '......jsonData')
                resolve(jsonData)
            }).catch(response => {
                reject(response.data)
            })
        } else {
            header = {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "x-requested-with,content-type",
                "Content-Type": "application/x-www-form-urlencoded"
            }
            axios.request({
                method,
                data, 
                header,
                url: BASEURL + url
            }).then(response => {
                resolve(response.data.data)
            }).catch(response => {
                reject(response.data)
            })
        }
    })
}
