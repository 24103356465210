import { obtainMusicScoreDetails } from '../../../config/api'
import { MusicScore } from '../../../proto/proto';
/**
 * 
 * @param {*} type  action 类型 
 * @param {*} data  action 数据  对象形式
 */
export const commonAction = (type, data) => ({
	type,
	data
});

// 获取曲谱详情
export const acquireMusicScoreDetails = (state) => {
	return async (dispatch) => {
		let data = await obtainMusicScoreDetails(MusicScore,'MusicScore.MusicScoreDetailsReqMsg','MusicScore.MusicScoreDetailsResMsg',state['id']);
		console.log(data,'获取曲谱详情')
		dispatch(commonAction(state['actionType'],data.data));
	}
}
