import React from 'react'

const component = ({isLoading, error}) => {
  // 加载中
  if (isLoading) {
    return <div></div>
  }
  // 加载出错
  else if (error) {
    return <div>Sorry, there was a problem loading the page.</div>
  }
  else {
    return null
  }
}
 
export default component