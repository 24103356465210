import * as actionTypes from './actionTypes';
const defaultState = {
	// 曲谱
	musicScore: [],
	// 曲谱 总数
	scoreTotal: 0,
	// 本月热门15
	currentMonthHot: []
};

export default (state = defaultState, action) => {
	console.log(action)
	let newState = JSON.parse(JSON.stringify(state));
	switch(action.type) {
		// 曲谱库数据
		case actionTypes.Obtain_Guitar_Hot_Music_Score:
			newState.musicScore = action.data.data;
			newState.scoreTotal = action.data.total;
			return newState;
		// 本月热门15
		case actionTypes.Obtain_Current_Month_Hot:
			newState.currentMonthHot = action.data;
			return newState;
		default:
			return state;
	}
}