import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// import './style.scss';
import styles from './style.module.scss';
import { actionCreators, actionTypes } from './store';
// import { BannerInfo } from '../../proto/proto';
// import { obtainMainBanner } from '../../config/api';
class headNavbar extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        console.log(this.props.location, '监听刷新 路由变化')
        const { location } = this.props
        switch(location.pathname) {
            case '/':
                this.props.operateChangeRouterIndex(1)
                break;
            case '/guitarScore':
                this.props.operateChangeRouterIndex(2)
                break;
            case '/uKuleleScore':
                this.props.operateChangeRouterIndex(3)
                break;
            //     return 'navbarUnderline navbarUnderlineThree';
            case '/appEclipse':
                this.props.operateChangeRouterIndex(4)
                break;
            case '/scoreSearch':
                this.props.operateChangeSearchValue(location.state.categoryIndex, location.state.searchText)
                break;
            default:
                break;
        }

        this.props.history.listen((router) => {
            console.log(router, '监听缓存 路由变化')
            switch(router.pathname) {
                case '/':
                    this.props.operateChangeRouterIndex(1)
                    break;
                case '/guitarScore':
                    this.props.operateChangeRouterIndex(2)
                    break;
                case '/uKuleleScore':
                    this.props.operateChangeRouterIndex(3)
                    break;
                //     return 'navbarUnderline navbarUnderlineThree';
                case '/appEclipse':
                    this.props.operateChangeRouterIndex(4)
                    break;
                case '/scoreSearch':
                    this.props.operateChangeSearchValue(router.state.categoryIndex, router.state.searchText)
                    break;
                default:
                    break;
            }
        })
    }
    render() {
        const { 
            history, 
            navigateIndex, 
            categoryIndex, 
            categoryShow, 
            searchText, 
            operateChangeRouter, 
            operateChangeCategory, 
            operateShowCategory,
            operateChangeText,
            operateConfirmText,
            operateEnterText 
        } = this.props;
        return (
            <div className={styles["headNavbar"]}>
                <div className={styles["headNavbarContent"]}>
                    <div className={styles["navbarLogo"]}>
                        <img src="https://mobuz-live.oss-cn-hangzhou.aliyuncs.com/web/matter/image/logo_big.png" alt="" />
                    </div>
                    <div className={styles["navbarList"]}>
                        <div className={(navigateIndex === 1)? `${styles["navbarCell"]} ${styles["active"]}`: styles["navbarCell"]} onClick = {
                            () => operateChangeRouter(history, 1)
                        }>首页</div>
                        <div className={(navigateIndex === 2)? `${styles["navbarCell"]} ${styles["active"]}`: styles["navbarCell"]} onClick = {
                            () => operateChangeRouter(history, 2)
                        }>吉他谱</div>
                        <div className={(navigateIndex === 3)? `${styles["navbarCell"]} ${styles["active"]}`: styles["navbarCell"]} onClick = {
                            () => operateChangeRouter(history, 3)
                        }>尤克里里</div>
                        <div className={(navigateIndex === 4)? `${styles["navbarCell"]} ${styles["active"]}`: styles["navbarCell"]} onClick = {
                            () => operateChangeRouter(history, 4)
                        }>APP下载</div>
                    </div>
                    <div className={styles["navbarSearch"]}>
                        <div className={styles["navbarSort"]} onClick = {
                            () => operateShowCategory(true)
                        }>
                            {
                                (() => {
                                    switch(categoryIndex) {
                                        case 1:
                                            return (
                                                <div className={styles["sortName"]}>吉他谱</div>
                                            )
                                        case 2:
                                            return (
                                                <div className={styles["sortName"]}>尤克里里谱</div>
                                            )
                                        default:
                                                return null;
                                                
                                    }
                                })()
                            }
                            <div className={styles["sortSign"]}>
                                <img src="https://mobuz-live.oss-cn-hangzhou.aliyuncs.com/web/icon/icon_arrow_down_gray%402x.png" alt="" />
                            </div>
                        </div>
                        <div className={styles["navbarDivide"]}><div className={styles["navbarLine"]}></div></div>
                        <div className={styles["navbarInput"]}>
                            <div className={styles["navbarInputBox"]}>
                                <input type="text" 
                                    placeholder="请输入歌曲名或者歌手名字" 
                                    cursor-spacing="10"
                                    value={searchText}
                                    onChange = {
                                        (e) => operateChangeText(e)
                                    }
                                    onKeyDown={
                                        (e) => operateEnterText(e, history, categoryIndex, searchText)
                                    } 
                                />
                            </div>
                        </div>
                        <div className={styles["navbarButton"]} onClick = {
                            () => operateConfirmText(history, categoryIndex, searchText)
                        }>
                            <div className={styles["searchIcon"]}>
                                <img src="https://mobuz-live.oss-cn-hangzhou.aliyuncs.com/web/icon/icon_search_white%402x.png" alt="" />
                            </div>
                            <div className={styles["searchText"]}>搜索</div>
                        </div>
                        <div className={categoryShow? styles["navbarSortList"]: `${styles["navbarSortList"]} ${styles["navbarSortListHide"]}`} onMouseLeave = {
                            () => operateShowCategory(false)
                        }>
                            <div className={styles["navbarSortListEmpty"]}></div>
                            <div className={styles["navbarSortListBox"]}>
                                <div className={(categoryIndex === 1)? `${styles["navbarSortCell"]} ${styles["active"]}`: styles["navbarSortCell"]} onClick = {
                                    () => operateChangeCategory(1)
                                }>吉他谱</div>
                                <div className={(categoryIndex === 2)? `${styles["navbarSortCell"]} ${styles["active"]}`: styles["navbarSortCell"]} onClick = {
                                    () => operateChangeCategory(2)
                                }>尤克里里谱</div>
                            </div>
                        </div>
                    </div>
                    <div className={
                        (() => {
                            switch(navigateIndex) {
                                case 1:
                                    return styles["navbarUnderline"];
                                case 2:
                                    return `${styles["navbarUnderline"]} ${styles["navbarUnderlineTwo"]}`;
                                case 3:
                                    return `${styles["navbarUnderline"]} ${styles["navbarUnderlineThree"]}`;
                                case 4:
                                    return `${styles["navbarUnderline"]} ${styles["navbarUnderlineFour"]}`;
                                default:
                                    return null;
                            }
                        })()
                    }></div>
                </div>
            </div>
        )
    }
}

const mapState = (state) => ({
    navigateIndex: state.headNavbar? state.headNavbar.navigateIndex: 1,
    categoryIndex: state.headNavbar? state.headNavbar.categoryIndex: 1,
    categoryShow: state.headNavbar? state.headNavbar.categoryShow: false,
    searchText: state.headNavbar? state.headNavbar.searchText: '',
});

const mapDispatch = (dispatch) => ({
    operateChangeRouter(history, type) {
        console.log(type, '.......')
        // window.history.go(0);
        let actionTypesData = {navigateIndex: type}
        switch(type) {
            case 1:
                history.push({
                    pathname: '/'
                })
                dispatch(actionCreators.commonAction(actionTypes.Change_Navigate_Index, actionTypesData))
                break;
            case 2:
                history.push({
                    pathname: '/guitarScore'
                })
                dispatch(actionCreators.commonAction(actionTypes.Change_Navigate_Index, actionTypesData))
                break;
            case 3:
                history.push({
                    pathname: '/uKuleleScore'
                })
                dispatch(actionCreators.commonAction(actionTypes.Change_Navigate_Index, actionTypesData))
                break;
            case 4:
                history.push({
                    pathname: '/appEclipse'
                })
                dispatch(actionCreators.commonAction(actionTypes.Change_Navigate_Index, actionTypesData))
                break;
            default:
                break;
        }
    },
    operateChangeRouterIndex(type) {
        let actionTypesData = {navigateIndex: type}
        dispatch(actionCreators.commonAction(actionTypes.Change_Navigate_Index, actionTypesData))
    },
    operateChangeSearchValue(categoryIndex, searchText) {
        let actionTypesData = {type: categoryIndex, text: searchText}
        dispatch(actionCreators.commonAction(actionTypes.Change_Search_Value, actionTypesData))
    },
    operateChangeCategory(type) {
        let actionTypesData = {categoryIndex: type}
        dispatch(actionCreators.commonAction(actionTypes.Change_Category_Index, actionTypesData))
    },
    operateShowCategory(flag) {
        let actionTypesData = {categoryShow: flag}
        dispatch(actionCreators.commonAction(actionTypes.Change_Category_Show, actionTypesData))
    },
    operateChangeText(e) {
        // dispatch(actionCreators.operateChangeText(e.target.value));
        let actionTypesData = {searchText: e.target.value}
        dispatch(actionCreators.commonAction(actionTypes.Change_Search_Text, actionTypesData))
    },
    async operateConfirmText(history, categoryIndex, searchText) {
        const _searchText = searchText.replace(/^\s+|\s+$/g,"")
        if (_searchText) {
            console.log('确定文本', categoryIndex, _searchText)
            history.push({
                pathname: '/scoreSearch',
                state: {
                    categoryIndex,
                    searchText: _searchText
                }
            })
            // const data = await obtainMainBanner(BannerInfo, 'BannerInfo.MainBannerReqMsg', 'BannerInfo.MainBannerResMsg');
            // console.log(data, '...data')
        }
    },
    operateEnterText(e, history, categoryIndex, searchText) {
        if (e.keyCode === 13) {
            mapDispatch(dispatch).operateConfirmText(history, categoryIndex, searchText)
        }
    }
});


export default connect(mapState, mapDispatch)(withRouter(headNavbar));