import { obtainMusicScoreData } from '../../../config/api'
import { MusicScore } from '../../../proto/proto';
import { __getPageScrollY } from '../../../config/utils'
/**
 * 
 * @param {*} type  action 类型 
 * @param {*} data  action 数据  对象形式
 */
export const commonAction = (type, data) => ({
	type,
	data
});

// 获取吉他谱 热门曲谱
export const acquireGuitarMusicScore = ( state ) => {
	return async (dispatch) => {
		/**
		 * 
		 * @param {int} type  	0 默认 1 吉他课 2 尤克里里
		 * @param {int} state   1 热门 2 最新
		 * @param {int} page    页码
		 * @param {int} size    一页几条
		 */
		let data = await obtainMusicScoreData(MusicScore,'MusicScore.MusicScoreReqMsg','MusicScore.MusicScoreResMsg',state['type'],state['state'],state['page'],state['size']);
		__getPageScrollY(0);
		dispatch(commonAction(state['actionType'],data));
	}
};
