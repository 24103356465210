import * as actionTypes from './actionTypes';

const defaultState = {
	// 吉他谱 热门曲谱
	hotGuitar: [],
	// 吉他谱 最新曲谱
	newGuitar: [],
	// 尤克里里 热门曲谱
	hotUKulele: [],
	// 尤克里里 最新曲谱
	newUKulele: [],
	// 首页banner
	mainBanner: [],
};

export default (state = defaultState, action) => {
	console.log(action)
	let newState = JSON.parse(JSON.stringify(state));
	switch(action.type) {
		case actionTypes.Obtain_Home_Music_Score_Data:
			newState.hotGuitar = action.data.hotGuitar || [];
			newState.newGuitar = action.data.newGuitar || [];
			newState.hotUKulele = action.data.hotUKulele || [];
			newState.newUKulele = action.data.newUKulele || [];
			return newState;
		case actionTypes.Obtain_Main_Banner:
			newState.mainBanner = action.data;
			return newState;
		default:
			return state;
	}
}