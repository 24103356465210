import * as actionTypes from './actionTypes';

const defaultState = {
	// 曲谱详情
	scoreDetails: {},
};

export default (state = defaultState, action) => {
	console.log(action)
	let newState = JSON.parse(JSON.stringify(state));
	switch(action.type) {
		case actionTypes.Obtain_Music_Score_Details:
			newState.scoreDetails = action.data;
			return newState;
			
		default:
			return state;
	}
}