import * as actionTypes from './actionTypes';

const defaultState = {
	navigateIndex: 1,
	categoryIndex: 1,
	categoryShow: false,
	searchText: ''
};

export default (state = defaultState, action) => {
	const newState = JSON.parse(JSON.stringify(state)) 
	switch(action.type) {
		case actionTypes.Change_Navigate_Index:
			const { navigateIndex } = action.data;
			newState.navigateIndex = navigateIndex;
			return newState;
		case actionTypes.Change_Category_Index:
			const { categoryIndex } = action.data;
			newState.categoryIndex = categoryIndex;
			return newState;
		case actionTypes.Change_Category_Show:
			const { categoryShow } = action.data;
			newState.categoryShow = categoryShow;
			return newState;
		case actionTypes.Change_Search_Text:
			const { searchText } = action.data;
			newState.searchText = searchText;
			return newState;
		case actionTypes.Change_Search_Value:
			const { type, text } = action.data;
			newState.categoryIndex = type;
			newState.searchText = text;
			return newState;
		default:
			return state;
	}
}