import { fetch } from './fetch'

/**
 * 首页
 * by Jehu
*/
// 获取 首页轮播图 数据
export const obtainMainBanner = (agreement, demand, respond) => fetch(agreement, demand, respond, 'POST', '/weapp/getMainBanner', {}, true)

/**
 * 曲谱库
 * by 雄
*/
/**
 *  获取曲谱 
 * @param {int} type  0 默认 1 吉他课 2 尤克里里  TODO 数据库 区分吉他与尤克里里字段改变 （1旧 0新）吉他 （2旧 1新）尤克里里
 * @param {int} state   1 热门 2 最新
 * @param {int} page    页码
 * @param {int} size    一页几条
 */
export const obtainMusicScoreData = (agreement, demand, respond, type, state , page , size) => fetch(agreement, demand, respond, 'POST', '/weapp/obtainMusicScoreData', {
    type: (type - 0 - 1 + ''), 
    state, 
    page,
    size
}, true)
// 获取首页曲谱库数据
export const obtainHomeMusicScoreData = (agreement, demand, respond) => fetch(agreement, demand, respond, 'POST', '/weapp/obtainHomeMusicScoreData', {}, true)
// 获取本月热度top15
export const obtainCurrentMonthHot = (agreement, demand, respond, type) => fetch(agreement, demand, respond, 'POST', '/weapp/obtainCurrentMonthHot', {
    type: (type - 0 - 1 + '')
}, true)
// 获取曲谱详情
export const obtainMusicScoreDetails = (agreement, demand, respond ,id) => fetch(agreement, demand, respond, 'POST', '/weapp/obtainMusicScoreDetails', {
    id
}, true)
// 获取曲谱详情
export const grabbleMusicScoreData = (agreement, demand, respond ,type, state , page , size, text) => fetch(agreement, demand, respond, 'POST', '/weapp/searchScore', {
    type: (type - 0 - 1 + ''), 
    state, 
    page, 
    size,
    text
}, true)

