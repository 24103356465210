// import OSS from 'ali-oss';
import { ALICLOUD } from './env';
import axios from 'axios';
import JsZip from 'jszip';
import { saveAs } from 'file-saver';
/**
 * 时间蹉转自定义格式时间
 * by Jehu
 */
export const dateRegExp = (time, strText) => {
    let date = new Date(time)
    // 匹配strText中是否有(y+)(至少一个y)的文本
    // ? 0或1个  + 至少一个 *至少0个
    if (/(y+)/.test(strText)) {
      // RegExp.$1是RegExp的一个属性,指的是与正则表达式匹配的第一个 子匹配(以括号为标志)字符串
      // substr(a) 从第a位开始截取 === substring(a)   substring(a,b)截取[a,b)间
      // replace(pattern,replacement) 把字符串中pattern替换成replacement 返回替换后的字符串
      // console.log(typeof (date.getFullYear())) number
      // console.log(typeof (date.getFullYear() + '')) string
      strText = strText.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let dataType = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (let typeKey in dataType) {
      // console.log(`/(${typeKey})/`)
      if (new RegExp(`(${typeKey})`).test(strText)) {
        let typeValue = dataType[typeKey] + ''
        // length从零开始算起的
        // console.log(RegExp.$1 + '****') MM
        // console.log(RegExp.$1.length + '***') 2
        // 判断模板的格式，若一个M就typeValue 两个MM就padLeftZero(typeValue)
        strText = strText.replace(RegExp.$1, (RegExp.$1.length === 1 ? typeValue : padLeftZero(typeValue)))
      }
    }
    return strText
}
/**
 * 数字前加 0
 * by Jehu
 */
export const padLeftZero = (str) => {
    // console.log(str + '....') 7
    // console.log(str.length + '...') 1
    // 若str=7 从第一位算起substr(1)  007 ==>07
    // 若str=17 从第二位算起substr(2)  0017 ==>17
    return ('00' + str).substr(str.length)
}
/**
 * 上传 阿里云 对象储存OSS 文件
 * 【file】欲上传文件  【path】阿里云存放位置
 * by Jehu
*/
export const upLoadFileToOSS = async(file, path) => {
    console.log('上传 阿里云 对象储存OSS 文件')
    let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    let res = "";
    for(let i = 0; i < 10; i++) {
        let id = Math.ceil(Math.random() * 61);
        res += chars[id];
    }
    // 保存到oss时的key，您可更改，默认以文件名作为key
    let key = res  + '.' + file.name.split(/\./g).pop();
    let result = await ALICLOUD.multipartUpload(('/'+path +'/'+ key), file, {})
    console.log(result)

}
/**
 * 下载 阿里云 对象储存OSS 文件
 * 【url】文件存储https地址  【path】阿里云存放位置
 * by Jehu
*/
export const downLoadFileFromOSS = (url, path) => {
    // console.log('下载 阿里云 对象储存OSS 文件', url)
    return new Promise((resolve, reject) => {
      let filename = url.replace(/(.*\/)*([^.]+).*/ig,"$2")+'.'+url.replace(/.+\./,"");
    //   console.log(filename, '.........')
      let urlOSS = ALICLOUD.signatureUrl(path+'/'+filename, {
        expires: 3600,
        response: {
          'content-disposition': 'attachment; filename="'+ filename +'"'
        }
      });
      resolve(urlOSS)
    })
}
/**
 * 打包 下载 https/http 文件
 * 【list】 下载文件url  【name】 打包文件名字  【path】 文件存储在阿里云位置 
 * by Jehu
*/
export const downloadFileFromLibrary = async (list, name, path) => {
    return new Promise( async (resolve, reject) => {
        const zip = new JsZip();
        // 在zip压缩包中 创建文件夹
        const _folder = zip.folder(name);
        for (let i = 0; i < list.length; i++ ) {
            const urlOSS = await downLoadFileFromOSS(list[i], path);
            console.log(urlOSS)
            if (urlOSS) {
                const resultInfo = await acquireFileBufferData(urlOSS)
                console.log(resultInfo, '....resultInfo', i)
                // 逐个添加文件
                // zip.file(name+'_'+(i+1)+'.jpg', resultInfo, { binary: true })
                _folder.file(name+'_'+(i+1)+'.jpg', resultInfo, { binary: true })
                if (i === list.length-1) {
                    zip.generateAsync({type:"blob"}).then(function(content) {
                        // see FileSaver.js
                        saveAs(content, name+".zip");
                        resolve(true);
                    });
                }
            }
        }
    })
}
/**
 * 获取文件二进制数据
 * 【url】 文件url 
 * by Jehu
*/
// 获取曲谱图片数据
export const acquireFileBufferData = (url) => {
    return new Promise((resolve, reject) => {
        axios({
            method:'get',
            url,
            responseType: 'arraybuffer'
        }).then(data => {
            resolve(data.data)
        }).catch(error => {
            reject(error.toString())
        })
    })
}

/**
 * localStorage  本地储存操作
 * 【 type 】 write 默认写入  writeArray 写入数组   read 读取   clear 清除
 * 【 count 】 写入数组最大个数
 * by Jehu
*/
export const localStorageTool = (type, key, value, count=3) => {
    if (type === 'write') {
        window.localStorage.setItem(key, value);
    }
    if (type === 'read') {
        return window.localStorage.getItem(key);
    }
    if (type === 'clear') {
        window.localStorage.removeItem(key);
    }
    if (type === 'writeArray') {
        // 判断该 存储对象是否存在
        let storageInfo = JSON.parse(window.localStorage.getItem(key));
        console.log('.........', storageInfo)
        if (storageInfo) {
            // 判断 欲储存的文本 是否存在 储存对象里面
            let storageIndex = storageInfo.indexOf(value)
            if (storageIndex > -1) {
                // 存在 【删除该元素  再插入到数组第一位】
                console.log('存在')
                let storageArray = storageInfo
                storageArray.splice(storageIndex, 1)
                storageArray.unshift(value)
                window.localStorage.setItem(key, JSON.stringify(storageArray));
            } else {
                // 不存在 【直接插入到 数组第一位】
                console.log('不存在')
                let storageArray =  storageInfo;
                storageArray.unshift(value);
                window.localStorage.setItem(key, JSON.stringify(storageArray));
            }

            // 限制 存储的个数  若到达 存储的最大个数 则需 delete 最后一个
            if (JSON.stringify(storageInfo) !== '[]') {
                if (storageInfo.length > count) {
                    // 则需 delete 最后一个
                    let storageArray = storageInfo
                    storageArray.pop()
                    window.localStorage.setItem(key, JSON.stringify(storageArray));
                }
            }
        } else {
            let storageArray = [];
            storageArray.push(value);
            window.localStorage.setItem(key, JSON.stringify(storageArray));
        } 
    }
}
/**
 * sessionStorage  本地储存操作
 * by Jehu
*/
export const sessionStorageTool = (type, key, value) => {
    if (type === 'write') {
        window.sessionStorage.setItem(key, value);
    }
    if (type === 'read') {
        return window.sessionStorage.getItem(key);
    }
    if (type === 'clear') {
        window.sessionStorage.removeItem(key);
    }
}

/**
 * 获取&&设置-页面垂直滚动值
 * */
export const __getPageScrollY = (top) => {

    if (top || Number(top) === 0) { //设置垂直滚动值
        if (window.pageYOffset) {
            window.pageYOffset = Number(top);
        }
        if (document.documentElement && document.documentElement.scrollTop) { // Explorer 6 Strict
            document.documentElement.scrollTop = Number(top);
        }
        if (document.body) {// all other Explorers
            document.body.scrollTop = Number(top);
        }
        return true;
    } else { //获取垂直滚动值
        var yScroll;
        if (window.pageYOffset) {
            yScroll = window.pageYOffset;
        } else if (document.documentElement && document.documentElement.scrollTop) { // Explorer 6 Strict
            yScroll = document.documentElement.scrollTop;
        } else if (document.body) {// all other Explorers
            yScroll = document.body.scrollTop;
        }
        return yScroll;
    }

};
// 获取url参数值
export const getQueryString = (name) =>  {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if ( r != null ){
       return unescape(r[2]);
    }else{
       return null;
    } 
 }
